import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { user, token } = action?.payload;
      
      if (token) {
        state.token = token;
      }
      if (user) {
        state.user = user;
      }


    },
    logout: (state) => {

      state.user = null;
      state.token = null;
    },
  },
});

export const { setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
