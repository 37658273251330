// import storeReducer from "../redux/slice/storeSlice";
import {baseApi} from "./api/baseApi";
import blogSlice from "./api/blog/blogSlice";
import cartReducer from "./api/cart/cartSlice";
import userReducer from "./api/user/userSlice";
import authReducer from "./api/Otp/otpSlice";
import uploadInfoReducer from "./api/upload/upload";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
export const reducer = {
  [baseApi.reducerPath]: baseApi.reducer,
  auth: persistedAuthReducer,
  cart: cartReducer,
  user: userReducer,
  blog: blogSlice,
  uploadInfo:uploadInfoReducer
};
