import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create();
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers["Accept"] = "application/json";
instance.defaults.timeout = 60000;


instance.interceptors.request.use(
  function (config) {
    const accessToken = JSON?.parse(localStorage?.getItem("persist:auth"));

    if (accessToken?.token) {
      config.headers.Authorization = `Bearer ${accessToken?.token.replace(
        /"/g,
        ""
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    const responseObject = {
      data: response?.data,
      meta: response?.data,
    };
    return responseObject;
  },
  function (error) {
    toast.error(
      error?.response?.data?.errorMessages[0]?.message || "Something went wrong"
    );
  
    return {
      error: {
        statusCode: error?.response?.data?.statusCode || 500,
        message: error?.response?.data?.message || "Something went wrong",
        errorMessages: error?.response?.data?.errorMessages[0]?.message,
        success: error?.response?.data?.success
      }
    };
    // return Promise.reject(error);
  }
);

export { instance };
