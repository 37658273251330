import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    total: 0,
    totalItems: 0,
    totalCourseDiscountAmount:0
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { total, product, totalItems,totalCourseDiscountAmount } = action.payload;
            state.products = product;
            state.total = total;
            state.totalItems = totalItems;
            state.totalCourseDiscountAmount = totalCourseDiscountAmount

        },
        removeFromCart: (state, action) => {
            // const { id } = action.payload;
            // const courses = state.products.find(p => p.id === id);
            // state.products = state.products.filter(p => p.id !== id);;
            // state.totalItems -= 1
            // state.total = state.price - courses?.CurrentCourseBatch?.CourseBatch?.price;

        }
    },
});


export const { addToCart, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;
