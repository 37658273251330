import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    total: 0,
    totalItems: 0,
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
       
    },
});


export const { addToCart } = blogSlice.actions;

export default blogSlice.reducer;
