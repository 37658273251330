import { createSlice } from "@reduxjs/toolkit";

const uploadInfo = {
    file: ""
};

const uploadSlice = createSlice({
    name: "uploadInfo",
    initialState: uploadInfo,
    reducers: {
        setUploadFile: (state, action) => {
            state.file = action.payload.file
           
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUploadFile } = uploadSlice.actions;

export default uploadSlice.reducer;
