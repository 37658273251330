
import { instance as axiosInstance } from './axiosInstance'

export const axiosBaseQuery = (
  { baseUrl } = { baseUrl: '' }
) => async ({ url, method, data, params, contentType }) => {
  try {
    const result = await axiosInstance({
      url: baseUrl + url,
      method,
      data,
      params,
      headers: {
        contentType: contentType || "application/json"
      }
    });
    if (result?.error) {
      return { data: result.error }
    }
    if (result?.data) {
      return { data: result.data };
    }

  } catch (axiosError) {
    console.log(axiosError, "habui");
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    };
  }
};
