import {axiosBaseQuery} from "@/helpers/axios/axiosBaseQuery";
import {createApi} from "@reduxjs/toolkit/query/react";

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({baseUrl: process.env.NEXT_PUBLIC_DB_HOST}),
  endpoints: () => ({}),
  tagTypes: [
    "auth",
    "user",
    "instructor",
    "blog",
    "blog-category",
    "job",
    "course-category",
    "course",
    "course-batch",
    "course-milestone",
    "course-module",
    "course-lession",
    "course-invite",
    "cart",
    "checkout",
    "payment",
    "transaction",
    "video",
    "quiz",
    "quiz-question",
    "quiz-attempt",
    "subscription-plan",
    "wishlist",
    "coupon",
    "withdraw",
    "assignment-mark",
    "course-review",
    "teams",
    "dashboard",
    "events",
    "announcement",
    "lead",
    "written-exam",
    "written-exam-attempt",
    "written-exam-question",
    "quiz-category",
    "written-exam-category",
  ],
});
